import React from "react"
import Seo from "../components/molecules/Seo"
import Splash from "../components/organisms/Splash"
import ContentHolder from "../components/organisms/ContentHolder"
import Image from "../components/atoms/Image"
import WorkTextBlock from "../components/molecules/WorkTextBlock"
import RelatedWork from "../components/organisms/RelatedWork"
import Container from "../components/atoms/Container"
import WorkImage from "../components/molecules/WorkImage"
import ArrowText from "../components/atoms/ArrowText"
import Numbers from "../components/organisms/Numbers"
import Number from "../components/molecules/Number"
import thumb from "../../static/images/anz-new-00001.jpg"

function Anz() {
  return (
    <>
      <Seo
        title="Extending the home loan pipeline | Craig Walker x ANZ"
        image={thumb}
        description="Craig Walker worked with the ANZ team to help them understand how people approach saving for a deposit and to design a minimum loveable product for these customers."
      />
      <Splash text={<>Extending the home loan&nbsp;pipeline</>} work={true}>
        <p>
          <b>
            Saving the deposit for a home takes first-home buyers in Australia
            anywhere from five to nine years, depending on which state they live
            in.
          </b>
        </p>

        <p>
          ANZ saw an opportunity to help first-home buyers build a savings plan
          to achieve their goal of owning a home sooner.
        </p>

        <p>
          Craig Walker worked with the ANZ team to help them understand how
          people approach saving for a deposit and to design a minimum loveable
          product for these customers.
        </p>

        <p>
          Working in-branch with home loan teams, we researched, designed and
          prototyped Your First Property Savings Plan alongside the local staff
          and with branch customers.
        </p>

        <p>
          A structured conversation supported by simple, paper-based tools, the
          savings plan gave bankers a way to help customers build a budget,
          capture their priorities and set a deposit savings goal. A supporting
          suite of tools and calculators are also available on anz.com.
        </p>
        <ArrowText>
          <p>Learn More</p>
        </ArrowText>
      </Splash>

      <ContentHolder>
        <Container>
          <Image imgName="anz-new-00001.jpg" />
        </Container>

        <Numbers>
          <Number number={3} text="Participating branches" />
          <Number number={10} text="Testing sessions" />
          <Number number={6} text="Weeks from discovery to beta" />
        </Numbers>

        <WorkTextBlock>
          <h2>Modular and&nbsp;personalised</h2>
          <p>
            Designed and tested with multiple branches, the modular tools give
            bankers ways to tailor the conversation to their customer’s personal
            circumstances. Customers take the completed plan home for future
            reference, action and follow-up.
          </p>
        </WorkTextBlock>

        <WorkImage>
          <Image imgName="anz-new-00002.jpg" />
        </WorkImage>

        <WorkTextBlock>
          <h2>Alpha to beta in six&nbsp;weeks</h2>
          <p>
            By working onsite in branches and with bank staff, we were able to
            prototype a set of tools for alpha testing within a week. These were
            tested and iterated into a beta version that was modular and
            specific to saving for a deposit.
          </p>

          <p>
            ANZ used the beta model as the starting point for quickly piloting
            and launching the new service.
          </p>
        </WorkTextBlock>

        <RelatedWork exclude="anz" />
      </ContentHolder>
    </>
  )
}

export default Anz
